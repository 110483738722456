import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default props => (
  <Layout {...props}>
    <Seo title='Privacy policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Privacy policy"
        subheader="In attuazione del regolamento UE 2016/679 e delle normative vigenti è gradito fornirle le seguenti informazioni attinenti ai trattamenti e alla protezione dei suoi dati personali."/>
        <Divider />
        <h3 id="titolare-del-trattamento">Titolare del trattamento</h3>
        <p>Criptosos.it, Bologna (BO), raggiungibile al numero 3519246659 oppure tramite indirizzo email info@criptosos.it</p>
        <h3 id="dato-personale-e-identificativo-">Dato personale e identificativo.</h3>
        <p>Per dato personale si intende, qualunque informazione relativa a persona fisica, identificata o identificabile, anche indirettamente, mediante riferimento a qualsiasi altra informazione, ivi compreso un numero di identificazione personale; Dati identificativi, i dati personali che permettono l’identificazione diretta dell’interessato (quali a titolo esemplificativo nome, cognome, indirizzo e-mail, indirizzo, numero di telefono, ecc…).</p>
        <h3 id="dati-di-navigazione">Dati di navigazione</h3>
        <p>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. Difesa in giudizio – I Dati Personali dell’Utente possono essere utilizzati per la difesa da parte del Titolare in giudizio o nelle fasi propedeutiche alla sua eventuale instaurazione, da abusi nell’utilizzo della stessa o dei servizi connessi da parte dell’Utente. I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito. Manutenzione – I Dati Personali dell’Utente possono essere trattati con modalità e finalità aggiuntive legate alla manutenzione dei sistemi.</p>
        <h3 id="dati-forniti-volontariamente-dall-utente">Dati forniti volontariamente dall’utente</h3>
        <p>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo, comporta la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti. Informative specifiche saranno presentate nelle pagine del Sito in relazione a particolari servizi o trattamenti dei Dati forniti dall’Utente o dall’Interessato.</p>
        <h3 id="cookie">Cookie</h3>
        <p>Si rimanda in fondo al documento al paragrafo cookie policy.</p>
        <h3 id="finalit-del-trattamento-per-le-quali-si-concede-consenso-laddove-richiesto">Finalità del trattamento per le quali si concede consenso laddove richiesto</h3>
        <p>I dati di natura personale volontariamente forniti saranno oggetto di trattamento per le seguenti finalità, fino a sua opposizione:</p>
        <ul>
        <li><p>svolgimento di operazioni strettamente connesse e strumentali alla gestione dei rapporti con gli utenti o dei visitatori del sito;</p>
        </li>
        <li><p>raccolta, conservazione ed elaborazione dei suoi dati per:</p>
        <ul>
        <li><p>analisi statistiche anche in forma anonima e/o aggregata;</p>
        </li>
        <li><p>analisi statistiche volte alla verifica della qualità dei servizi offerti dal sito;</p>
        </li>
        </ul>
        </li>
        </ul>
        <h3 id="modalit-del-trattamento-conservazione">Modalità del trattamento – Conservazione</h3>
        <p>Il trattamento sarà svolto in forma automatizzata e manuale, con modalità e strumenti volti a garantire la massima sicurezza e riservatezza, ad opera di soggetti di ciò appositamente autorizzati.</p>
        <h3 id="tempi-di-conservazione">Tempi di conservazione</h3>
        <p>I dati saranno conservati per un periodo non superiore agli scopi per i quali i dati sono stati raccolti e successivamente trattati.</p>
        <h3 id="ambito-di-comunicazione-e-diffusione">Ambito di comunicazione e diffusione</h3>
        <p>I Suoi dati, oggetto del trattamento, non saranno diffusi venduti o scambiati con soggetti terzi senza il consenso espresso dall’interessato. I dati potranno essere comunicati a terzi appartenenti alle seguenti categorie:</p>
        <ul>
        <li>soggetti che forniscono servizi per la gestione del sistema informativo</li>
        <li>studi o società nell’ambito di rapporti di assistenza e consulenza;</li>
        <li>autorità competenti per adempimenti di obblighi di leggi e/o di disposizioni di organi pubblici, su richiesta.</li>
        <li>provider esterno;</li>
        <li>società che effettuano la manutenzione ordinaria e straordinaria del sito web</li>
        </ul>
        <p>I suoi dati non saranno comunque diffusi.</p>
        <h3 id="trasferimento-dei-dati-all-estero">Trasferimento dei dati all’estero</h3>
        <p>I dati personali dell’interessato non saranno trasmessi verso Paesi terzi. Nel caso si dovesse in futuro trasferire dati personali verso paesi terzi o organizzazioni internazionali, verranno rispettate tutte le disposizioni del capo V (Regolamento UE 2016/679) al fine di assicurare un livello di protezione adeguato.</p>
        <h3 id="diritti-dell-interessato">Diritti dell’interessato</h3>
        <p>L’interessato ha facoltà di chiedere al titolare del trattamento l’accesso ai dati personali, la rettifica o la cancellazione degli stessi o la limitazione del trattamento o di opporsi al loro trattamento. Lei ha il diritto inoltre di richiedere specifica completa dei diritti scrivendo all’indirizzo del Titolare.</p>
        <h3 id="diritto-di-reclamo-autorit-di-controllo">Diritto di reclamo autorità di controllo</h3>
        <p>Se ritiene che il trattamento che lo riguarda violi la sua dignità ha il diritto di proporre reclamo all’Autorità di Controllo pertinente.</p>
        </Main>
    </Stack>
  </Layout>
)
